import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontSize: 12,
    fontStyle: "bold",
  },
  nameription: {
    width: "85%",
    textAlign: "right",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
  },
  total: {
    width: "15%",
    textAlign: "right",
    paddingRight: 8,
  },
});

const InvoiceTableFooter = ({ items, tax, discount, total }) => {
  return (
    <View>
      {items && (
        <View style={styles.row}>
          <Text style={styles.nameription}>TOTAL</Text>
          <Text style={styles.total}>
            {Number.parseFloat(total).toFixed(2)}
          </Text>
        </View>
      )}
      {discount && (
        <View style={styles.row}>
          <Text style={styles.nameription}>Discount</Text>
          <Text style={styles.total}>
            {Number.parseFloat(discount).toFixed(2)}
          </Text>
        </View>
      )}
      {tax && (
        <View style={styles.row}>
          <Text style={styles.nameription}>Tax</Text>
          <Text style={styles.total}>
            {Number.parseFloat(tax * 100).toFixed(2)}%
          </Text>
        </View>
      )}
    </View>
  );
};

export default InvoiceTableFooter;
