import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import InvoiceTableHeader from "./InvoiceTableHeader";
import InvoiceTableRow from "./InvoiceTableRow";
import InvoiceTableBlankSpace from "./InvoiceTableBlankSpace";
import InvoiceTableFooter from "./InvoiceTableFooter";

const tableRowsCount = 11;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
});

const InvoiceItemsTable = ({ invoice }) => {
  console.log(invoice);
  return (
    invoice &&
    invoice.items && (
      <View style={styles.tableContainer}>
        <InvoiceTableHeader />
        <InvoiceTableRow items={invoice.items} />
        <InvoiceTableBlankSpace
          rowsCount={tableRowsCount - invoice.items.length}
        />
        <InvoiceTableFooter total={invoice.total} discount={invoice.discount} />
        <InvoiceTableFooter total={invoice.total} tax={invoice.tax} />
        <InvoiceTableFooter total={invoice.total} items={invoice.items} />
      </View>
    )
  );
};

export default InvoiceItemsTable;
