export const initialState = {
  user: null,
  selected: [],
  profile: [],
  booking: [],
  loading: false,
  basket: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.user,
      };
    case "SET_PROFILE":
      return {
        ...state,
        profile: action.profile,
      };
    case "SELECTED_PROFILE":
      return {
        ...state,
        selected: action.selected,
      };
    case "BOOKING":
      return {
        ...state,
        booking: action.booking,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.loading,
      };
    case "ADD_TO_CART":
      return {
        ...state,
        basket: [...state.basket, action.item],
      };
    case "UPDATE_CART":
      return {
        ...state,
        basket: action.basket,
      };
    case "REMOVE_FROM_CART":
      return {
        ...state,
        basket: state.basket.filter((item) => item.id !== action.id),
      };
    default:
      return state;
  }
};
export default reducer;
