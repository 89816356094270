// import sanitizeHTML from 'sanitize-html'; // use this or change to react-render-html
import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore/lite";
import moment from "moment";
import Cookies from "universal-cookie";

const cookies = new Cookies();

//To generate Unique Key for any given component
export const generateUniqueComponentKey = () => {
  return crypto.randomBytes(16).toString("hex");
};
//To generate imagekit-url-endpoint
export const generateImageURL = (endpoint, url, height, width) => {
  if (height && width) {
    return `${endpoint}${url}?tr=h-${height},w-${width}`;
  }
  if (height) {
    return `${endpoint}${url}?tr=h-${height}`;
  }
  if (width) {
    return `${endpoint}${url}?tr=w-${width}`;
  }
  return `${endpoint}${url}`;
};
export const getId = async (value, data) => {
  let allUIDs = data.map((item, i) => {
    if (item.uid.search(value) !== -1) {
      return item.uid;
    }
  });
  let final = allUIDs.filter((item) => item !== undefined)[0];
  return final;
};

export const formatImageURL = (url) => {
  if (!url) return "";
  return url.startsWith("http") ? url : `https://${url}`;
};

export const setCookies = (name, value) => {
  let expire = new Date();
  expire.setDate(expire.getDate() + 365);
  cookies.set(name, value, { expires: expire, path: "/" });
};

export const getCookies = (name) => {
  return cookies.get(name);
};

export const removeCookies = (name, options = { path: "/" }) => {
  cookies.remove(name, options);
};

/*
  Description : Format date in specified format. Mongo date are stored in UTC. When we use format from date-fns, it will automatically convert dates into local timezone (which in out case is EST). So UTC string like 2021-02-05T00:00:00.000Z can be converted to Feb 1, 2021. To avoid this, we offset the incoming date with current timezone and than apply format
*/

export const _dateSort = (currentDate, futureDate, order) => {
  if (currentDate && futureDate && order) {
    if (order === "asc") {
      return Date.parse(currentDate) - Date.parse(futureDate);
    } else if (order === "desc") {
      return Date.parse(futureDate) - Date.parse(currentDate);
    }
  }
};

export const FormatDateString = (
  date,
  DateFormat = "YYYY-MM-DD",
  SubtractDay = 0
) => {
  if (!date) return "";
  const dateobj = new Date(date);
  if (SubtractDay > 0) dateobj.setDate(dateobj.getDate() - SubtractDay);

  let month = dateobj.getUTCMonth() + 1;
  if (month < 10) month = `0${month}`;

  let day = dateobj.getUTCDate();
  if (day < 10) day = `0${day}`;

  let year = dateobj.getUTCFullYear();
  switch (DateFormat) {
    case "YYYY-MM-DD":
      return `${year}-${month}-${day}`;
    case "YYYY/MM/DD":
      return `${year}/${month}/${day}`;
    case "MM/DD/YYYY":
      return `${month}/${day}/${year}`;
    case "MM-DD-YYYY":
      return `${month}-${day}-${year}`;
    case "MM-DD":
      return `${month}-${day}`;
    case "MM/DD":
      return `${month}/${day}`;
    case "MMDD":
      return `${month}${day}`;
    case "YY":
      return year;
    case "MM":
      return month;
    case "DD":
      return day;
    default:
      return ``;
  }
};

// this function will change the format of the date without turning them to date objects of JS
export const FormatDate = (date, inputFormat, changeFormat = "YYYY-MM-DD") => {
  if (!date) return "";
  let formatDate;
  if (inputFormat) {
    formatDate = moment(date, inputFormat).format(changeFormat);
  } else {
    formatDate = moment(date).format(changeFormat);
  }
  return formatDate;
};

// this function will just change the format without using JS date Object or moment or date-fns
// this should be in MM/DD/YYYY format and this will return YYYY-MM-DD
export const FormatDateTable = (date) => {
  if (!date) return "";
  const splittedArr = date.split("/");
  let d, month, year;
  if (Number(splittedArr[0]) && Number(splittedArr[0]) <= 12) {
    month = Number(splittedArr[0]);
    if (month < 10) month = `0${month}`;
  } else {
    month = FormatDateString(date, "MM");
  }
  if (Number(splittedArr[1]) && Number(splittedArr[1]) <= 31) {
    d = Number(splittedArr[1]);
    if (d < 10) d = `0${d}`;
  }
  if (splittedArr[2]) {
    year = FormatDateString(date, "YY");
  } else {
    year = FormatDateString(new Date(), "YY");
  }
  if (year && d && month) return `${year}-${month}-${d}`;
  return moment(date);
};

export const isValidDateFormat = (date, dateFormat = "MM/DD/YYYY") => {
  if (!date) return false;
  const validDate = moment(date, dateFormat).format("YYYY-MM-DD");
  if (!moment(validDate).isValid()) return false;
  return true;
};

// returns the difference between two dates
// will return false if dates are not valid
export const DiffDates = (oldDate, newDate) => {
  if (!oldDate || !newDate) return false;
  if (typeof oldDate !== "object") oldDate = new Date(oldDate);
  if (typeof newDate !== "object") newDate = new Date(newDate);
  if (!moment(oldDate).isValid() || !moment(newDate).isValid()) return false;

  return moment(oldDate).diff(moment(newDate), "days");
};

// will check for dates - returns false if dates are not valid
// returns 0 when date are equal // type is number of 0
// returns 1 when olddate is greater than newdate
// returns -1 when olddate is less than newdate
export const CompareDates = (oldDate, newDate) => {
  if (!oldDate || !newDate) return false;
  oldDate = new Date(oldDate);
  newDate = new Date(newDate);
  if (!moment(oldDate).isValid() || !moment(newDate).isValid()) return false;

  const number = moment(oldDate).diff(moment(newDate), "days");
  if (number === 0) return 0;
  if (number < 0) return -1;
  else return 1;
};

export const getTodaysDate = () => {
  return moment().format("YYYY-MM-DD");
};

export const getOneWeekBeforeDate = () => {
  return moment().subtract(7, "days").format("YYYY-MM-DD");
};

export const getOneMonthBefore = () => {
  const dateobj = new Date();
  let year = dateobj.getFullYear();
  let month = dateobj.getUTCMonth();
  if (month === 0) {
    year = year - 1;
    month = 12;
  }
  if (month < 10) month = `0${month}`;
  let day = dateobj.getUTCDate();
  if (day < 10) day = `0${day}`;

  return `${year}-${month}-${day}`;
};

// export const FormatHTML = (value) => {
//   if(value) {
//     const returnValue = sanitizeHTML(value,{
//       // Don't want to allow any tag or any attribute to pass
//       allowedTags: [],
//       allowedAttributes: {},
//     });
//     return returnValue;
//   }
// };

export const generateURL = (siteName, slug) => {
  let environment = `${process.env.REACT_APP_ORION_ENV}`;
  let protocol = "https";
  if (environment === "local") {
    protocol = "http";
    environment = "local.";
  } else if (environment === "staging") {
    environment = "staging.";
  }
  return `${protocol}://${environment}${siteName}/${slug}`;
};

export const generateCampaignLink = (_id, campaignRoute) => {
  if (typeof window !== "undefined") {
    const hostname = window.location.hostname;
    let environment = "";
    let protocol = "https";
    if (hostname.includes("local")) {
      environment = "local.";
      protocol = "http";
    } else if (hostname.includes("staging")) {
      environment = "staging.";
    }

    switch (campaignRoute) {
      case "freebook":
        return `${protocol}://${environment}earlybirdbooks.com/${campaignRoute}/index.html?q=${_id}`;
      case "giveaway":
        return [
          `${protocol}://${environment}earlybirdbooks.com/${campaignRoute}?q=${_id}`,
          `${protocol}://${environment}the-line-up.com/${campaignRoute}?q=${_id}`,
          `${protocol}://${environment}theportalist.com/${campaignRoute}?q=${_id}`,
          `${protocol}://${environment}explorethearchive.com/${campaignRoute}?q=${_id}`,
          `${protocol}://${environment}alovesotrue.com/${campaignRoute}?q=${_id}`,
          `${protocol}://${environment}murder-mayhem.com/${campaignRoute}?q=${_id}`,
        ];
      default:
        return `${protocol}://${environment}earlybirdbooks.com/${campaignRoute}?q=${_id}`;
    }
  }
};

/**
 * title: title of the book
 * primaryisbn: isbn of the book
 * asin: asin of the book
 * googleId: google id of the book
 * appleId: apple id of the book
 * appleEan: apple ean of the book
 * retailerSiteLinks: links coming from DB, if any
 * amazon: amazon affiliate tag coming from sitesettings
 * apple: apple affiliate tag coming from sitesettings
 * barnes_nobles: b&n affiliate tag coming from sitesettings
 * kobo: kobo affiliate tag coming from sitesettings
 * takes retailer affiliate and returns a array of objects with retailer affiliates links
 */

export const validatesellingPrice = (sellingPrice) => {
  let regex = /^\d+(?:\.\d{0,2})$/;
  return regex.test(sellingPrice);
};

export const formatBookCoverImage = (image) => {
  let imagePath = `${process.env.REACT_APP_BOOK_COVER_S3_BASE_URL}/${image}`;
  return imagePath;
};
export const formatPartnerTitleCSVData = (data) => {
  let formatedCSV = [];
  if (data.length) {
    for (var i = 0; i < data.length; i++) {
      let authorsArray = data[i]["Authors"].split(" & ");

      let authors = [];
      let rowData = {
        item_type: "Book",
        title: data[i]["Title"],
        publisher: data[i]["Company"],
        asin: data[i]["ASIN"],
        sale_dlp: data[i]["Sale DLP"],
        sellingPrice: data[i]["DLP"],
        primary_isbn: data[i]["Primary ISBN"],
        image: formatBookCoverImage(data[i]["Book Cover"]),
        created: new Date(),
        partner_title: true,
        active: true,
        multipleAuthors: data[i]["Authors"],
        google_id: data[i]["Google_Id"],
      };
      authorsArray &&
        authorsArray.map((author) => {
          let row = {};
          row.role = 1;
          row.display_name = author;
          authors.push(row);
        });
      rowData.authors = authors;
      formatedCSV.push(rowData);
    }
  }
  return formatedCSV;
};

/* 
  Function provide monthday from data.
  For ex:-
  06/22/2021 ==> 622
  01/01/2021 ==> 101
*/
export const CalculateMonthDayEvent = (incomingDate, day) => {
  if (incomingDate) {
    let formateddate = FormatDateString(incomingDate, "YYYY-MM-DD");
    let startdatemonthday = FormatDateString(formateddate, "MMDD", day);
    let finalnumber = Number(startdatemonthday);
    return finalnumber;
  }
};

//This function will return total days of the month
export const CalculateTotalDaysInMonth = (month, year) => {
  let days = [
    31,
    year % 4 === 0 ? 29 : 28,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31,
  ];
  return days[parseInt(month)];
};

export const GetMonthFirstLastDate = (date, day = "first") => {
  let dateObj = new Date(date);
  let year = dateObj.getUTCFullYear();
  let month = dateObj.getUTCMonth() + 1;
  if (month < 10) month = `0${month}`;

  let finalDate = `${year}-${month}-01`;
  if (day !== "first") {
    let lastDay = CalculateTotalDaysInMonth(month - 1, year);
    finalDate = `${year}-${month}-${lastDay}`;
  }
  return finalDate;
};

// export const GetNextMonthFirstLastDate = (date, day = 'first') => {
//   let dateObj = new Date(date);
//   let year = dateObj.getUTCFullYear();
//   let month = dateObj.getUTCMonth()+2;
//   if (month < 10)
//     month = `0${month}`;

//   let finalDate = `${year}-${month}-01`;
//   if(day!=='first') {
//     let lastDay = CalculateTotalDaysInMonth(month-1, year);
//     finalDate =  `${year}-${month}-${lastDay}`;
//   }
//   return finalDate;
// }

export function removeItemOnce(arr, value) {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}
export function checkOnboarding(user, router) {
  if (user.onboarding < 1) {
    router?.replace("/Onboarding");
  }
}
export const refreshData = (router) => {
  router?.replace(router.asPath);
};
export const getDocuments = async (db, collectionId, id) => {
  if (id) {
    const docRef = doc(db, collectionId, id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    }
  } else {
    const getUsers = await getDocs(collection(db, collectionId));
    var docs = [];
    getUsers.forEach((doc) => {
      docs.push(doc.data());
    });

    return docs;
  }
};
export const updateDocument = async (db, collectionId, id, data) => {
  const updateRef = doc(db, collectionId, id);
  const updated = await updateDoc(updateRef, data);
  return updated;
};
export const validURL = (str) => {
  let pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

export const inputValueChange = (e, key, field, state, setState) => {
  var array =
    !state[field] || state[field].length === 0
      ? (state[field] = [])
      : state[field];
  key === "checkbox" && e.target.checked && array.push(e.target.value);
  key === "checkbox" &&
    !e.target.checked &&
    removeItemOnce(array, e.target.value);
  setState({
    ...state,
    [field]:
      key === "checkbox"
        ? array
        : key === "file"
        ? e.target.files[0]
        : e.target.value,
  });
};
export const validateEmailFormat = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
export const validatePhoneFormat = (phone) => {
  const re = /^\d{10}$/;
  return re.test(String(phone).toLowerCase());
};

export const validatePassword = (password) => {
  const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
  return re.test(password);
};

export const removeHTML = (str) => {
  if (!str) return "";
  var tmp = document.createElement("DIV");
  tmp.innerHTML = str;
  return tmp.textContent || tmp.innerText || "";
};

export const wordCounter = (str) => {
  let s = str.replace(/(^\s*)|(\s*$)/gi, "");
  s = s.replace(/[ ]{2,}/gi, " ");
  s = s.replace(/\n /, "\n");
  return s ? s.split(" ").length : 0;
};
