import React, { useEffect, useState } from "react";
import {
  Page,
  Document,
  Image,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import InvoiceTitle from "./InvoiceTitle";
import BillTo from "./BillTo";
import InvoiceNo from "./InvoiceNo";
import InvoiceItemsTable from "./InvoiceItemsTable";
import InvoiceThankYouMsg from "./InvoiceThankYouMsg";
import { getDocuments } from "utils/Helper";
import { useHistory } from "react-router-dom";
import { getFirestore } from "firebase/firestore/lite";
// import logo from "../../../src/logo.png";
import db from "contexts/Firebase";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: "auto",
    marginRight: "auto",
  },
});

const Invoice = ({}) => {
  const invoice = {
    id: "5df3180a09ea16dc4b95f910",
    invoice_no: "201906-28",
    balance: "$2,283.74",
    company: "MANTRIX",
    email: "susanafuentes@mantrix.com",
    phone: "+1 (872) 588-3809",
    address: "922 Campus Road, Drytown, Wisconsin, 1986",
    trans_date: "2019-09-12",
    due_date: "2019-10-12",
    items: [
      {
        sno: 1,
        description: "ad sunt culpa occaecat qui",
        quantity: 5,
        sellingPrice: 405.89,
      },
    ],
  };
  const [data, setdata] = useState([]);
  const history = useHistory();
  const id = history.location.pathname.split("/")[3];
  const get = async () => {
    const main = await getDocuments(db, "invoices", id);
    setdata(main);
  };
  useEffect(() => {
    get();
  }, []);
  return data.length !== 0 ? (
    <PDFViewer width={window.innerWidth} height={window.innerHeight}>
      <Document>
        <Page size="A4" style={styles.page}>
          {/* <Image style={styles.logo} src={logo} /> */}
          <InvoiceTitle title="Invoice" />
          <InvoiceNo invoice={data} />
          <BillTo invoice={data} />
          <InvoiceItemsTable invoice={data} />
          <InvoiceThankYouMsg />
        </Page>
      </Document>
    </PDFViewer>
  ) : (
    <>Invalid Invoice id</>
  );
};

export default Invoice;
