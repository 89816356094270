// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import { toast } from "react-toastify";

// Custom Components
import { ItemContent } from "components/menu/ItemContent";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { SidebarResponsive } from "components/sidebar/Sidebar";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
// Assets
import navImage from "assets/img/layout/Navbar.png";
import { MdNotificationsNone, MdInfoOutline } from "react-icons/md";
import { FaEthereum } from "react-icons/fa";
import routes from "routes.js";
import { ThemeEditor } from "./ThemeEditor";
import { CgShoppingCart } from "react-icons/cg";
import CustomDrawer from "components/drawer";
import { useStateValue } from "contexts/StateProvider";
import { useHistory } from "react-router-dom";
import { doc, getFirestore, setDoc, updateDoc } from "firebase/firestore/lite";
import axios from "axios";
import { async } from "@firebase/util";
import { removeCookies } from "utils/Helper";
import db from "contexts/Firebase";
import { getDocuments } from "utils/Helper";
// import { uuid } from "uuidv4";
const PROMOTIONS = [
  {
    code: "thewebvale",
    discount: "50%",
  },
  {
    code: "jain",
    discount: "40%",
  },
  {
    code: "yash",
    discount: "30%",
  },
];

const TAX = 0.12;

export default function HeaderLinks(props) {
  const history = useHistory();
  const [notifications, setNotifications] = useState([]);

  const getNotifications = async () => {
    const neww = await getDocuments(db, "notifications");
    setNotifications(neww);
    return neww;
  };

  useEffect(() => {
    getNotifications();
  }, []);
  const { secondary } = props;
  // Chakra Color Mode
  const locked = JSON.parse(localStorage.getItem("adminToken"));
  const [{ basket }, dispatch] = useStateValue();
  const user = JSON.parse(localStorage.getItem("UserToken"));
  const navbarIcon = useColorModeValue("gray.400", "white");
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.700", "brand.400");
  const ethColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const ethBg = useColorModeValue("secondaryGray.300", "navy.900");
  const ethBox = useColorModeValue("white", "navy.800");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const borderButton = useColorModeValue("secondaryGray.500", "whiteAlpha.200");
  const [promoCode, setPromoCode] = React.useState("");
  const [discountPercent, setDiscountPercent] = React.useState(0);
  const itemCount = basket.reduce((quantity, product) => {
    return quantity + +product.quantity;
  }, 0);
  const subTotal = basket.reduce((total, product) => {
    return total + product.sellingPrice * +product.quantity;
  }, 0);

  const discount = (subTotal * discountPercent) / 100;
  const onChangeProductQuantity = (index, event, stock) => {
    const value = event.target.value;

    const valueInt = parseInt(value);
    const clonebasket = basket;

    // Minimum quantity is 1, maximum quantity is 100, can left blank to input easily
    if (value === "") {
      clonebasket[index].quantity = value;
    } else if (valueInt > 1 && valueInt <= stock) {
      clonebasket[index].quantity = valueInt;
      clonebasket[index].stock = stock - valueInt + 1;
    }

    // dispatch({
    //   type: "UPDATE_CART",
    //   basket: clonebasket,
    // });
  };
  const onRemoveProduct = (i) => {
    const filteredProduct = basket.filter((product, index) => {
      return index != i;
    });
    dispatch({
      type: "UPDATE_CART",
      basket: filteredProduct,
    });
  };
  const increase = (index, event, stock) => {
    const valueInt = parseInt(event);
    const clonebasket = basket;
    if (clonebasket[index].stock <= 0) {
      toast.error("No Stock Available", { toastId: index });
    } else {
      clonebasket[index].quantity = clonebasket[index].quantity + 1;
      clonebasket[index].stock = clonebasket[index].stock - 1;
    }

    dispatch({
      type: "UPDATE_CART",
      basket: clonebasket,
    });
  };

  const decrease = (index, event, stock) => {
    const valueInt = parseInt(event);
    const clonebasket = basket;

    if (clonebasket[index].quantity <= 1) {
      onRemoveProduct(index);
      toast.success("Product Removed", { toastId: index });
    } else {
      clonebasket[index].quantity = clonebasket[index].quantity - 1;
      clonebasket[index].stock = clonebasket[index].stock + 1;
      dispatch({
        type: "UPDATE_CART",
        basket: clonebasket,
      });
    }
  };

  const onEnterPromoCode = (event) => {
    setPromoCode(event.target.value);
  };

  const checkPromoCode = () => {
    for (var i = 0; i < PROMOTIONS.length; i++) {
      if (promoCode === PROMOTIONS[i].code) {
        setDiscountPercent(parseFloat(PROMOTIONS[i].discount.replace("%", "")));
        toast.success("Promocode applied", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
    }

    toast.error("Enter a valid Code", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const staging = localStorage.staging;

  const [loading, setloading] = useState(false);
  const [sellingPrice, setsellingPrice] = useState(0);
  var randLetter = String.fromCharCode(65 + Math.floor(Math.random() * 26));
  var uniqid = randLetter + Date.now();

  const final = subTotal - discount;
  const total = final + final * TAX;
  const generateInvoice = async (e) => {
    e.preventDefault();
    const uid = new Date().getTime().toString();
    const invoice = {
      id: uid,
      invoice_no: uid,
      balance: format_sellingPrice(total),
      company: "UDAY Sports",
      email: "yaashjainn@@gmail.com",
      phone: "+91 8770183178",
      address: "922 Campus Road, Drytown, Wisconsin, 1986",
      trans_date: new Date().toLocaleDateString(),
      due_date: new Date().toLocaleDateString(),
      items: basket,
      total: total,
      discount: discount,
      tax: TAX,
    };
    await setDoc(doc(db, "invoices", invoice.id), invoice);
    await setDoc(doc(db, "notifications", invoice.id), {
      notification: `${basket.length} sold by ${
        user?.displayName
      } at ${format_sellingPrice(total)}`,
      link: "/auth/invoice/" + invoice.id,
      button: "View Invoice",
      id: invoice.id,
      createdAt: new Date().toLocaleDateString(),
      isRead: false,
      user: user,
      label: "Items Sold",
    });

    // Update Stock
    basket.map(async (i, index) => {
      delete i.quantity;
      i.sno = index + 1;
      parseInt(i.sellingPrice);
      parseInt(i.quantity);

      await updateDoc(doc(db, "products", i.name), i);
    });
    history.push("/auth/invoice/" + invoice.id);
  };
  console.log(process.env.NEXT_PUBLIC_LOCKED, locked);
  return (
    <Flex
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
      p="10px"
      borderRadius="30px"
      boxShadow={shadow}
    >
      <SearchBar
        mb={secondary ? { base: "10px", md: "unset" } : "unset"}
        me="10px"
        borderRadius="30px"
      />
      <Flex
        display={secondary ? "flex" : "none"}
        borderRadius="30px"
        ms="auto"
        p="1px"
        align="center"
        me="1px"
      >
        <Flex
          align="center"
          justify="center"
          bg={ethBox}
          display={secondary ? "flex" : "none"}
          h="10px"
          w="10px"
          borderRadius="30px"
          me="7px"
        >
          <CustomDrawer size="lg">
            <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
              <div className="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
                <div className="flex items-start justify-between">
                  <h2
                    className="text-lg font-medium text-gray-900"
                    id="slide-over-title"
                  >
                    Shopping cart
                  </h2>
                </div>
              </div>
              {basket.length > 0 ? (
                <div className="flex flex-col">
                  <div className=" w-full">
                    <ul className="flex bg-white rounded-2xl w-full flex-col ">
                      {basket.map((product, index) => (
                        <div
                          key={index}
                          className="left border p-3 rounded-xl m-4"
                        >
                          <li className="flex w-full flex-col px-4 sm:flex-row sm:justify-between">
                            <div className="flex w-full space-x-2 sm:space-x-4">
                              {/* <img
                                className="flex-shrink-0 object-cover w-20 h-20  rounded outline-none sm:w-32 sm:h-32 "
                                src={product.image}
                                alt={product.name}
                              /> */}
                              <div className="flex flex-col justify-between w-full pb-4">
                                <div className="flex justify-between w-full pb-2 space-x-2">
                                  <div className="space-y-1">
                                    <h6 className="capitalize text-lg font-semibold leading-snug sm:pr-8">
                                      {product.name}
                                    </h6>
                                  </div>

                                  <div className="text-right">
                                    <p className="text-lg font-semibold">
                                      {format_sellingPrice(
                                        product.sellingPrice
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <div className="flex my-2 items-center justify-start flex-row">
                                  <div
                                    onClick={() => {
                                      increase(index, 1, product.stock);
                                    }}
                                    className="shadow-sm mr-1 bg-gray-50 cursor-pointer border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-fit p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M12 4.5v15m7.5-7.5h-15"
                                      />
                                    </svg>
                                  </div>
                                  <div className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-fit p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light">
                                    {product.quantity}
                                  </div>

                                  <div
                                    onClick={() => {
                                      decrease(index, 1, product.stock);
                                    }}
                                    className="shadow-sm ml-1 cursor-pointer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-fit p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M19.5 12h-15"
                                      />
                                    </svg>
                                  </div>
                                </div>

                                <div
                                  onClick={() => {
                                    onRemoveProduct(index);
                                  }}
                                  className="p-2 rounded-2xl flex border text-gray-600 w-fit text-sm"
                                >
                                  <button
                                    type="button"
                                    className="flex items-center px-2 py-1 pl-0 space-x-1"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512 512"
                                      className="w-4 h-4 fill-current"
                                    >
                                      <path d="M96,472a23.82,23.82,0,0,0,23.579,24h692.421A23.82,23.82,0,0,0,416,472V152H96Zm32-288h684V464H128Z"></path>
                                      <rect
                                        width="32"
                                        height="200"
                                        x="168"
                                        y="216"
                                      ></rect>
                                      <rect
                                        width="32"
                                        height="200"
                                        x="240"
                                        y="216"
                                      ></rect>
                                      <rect
                                        width="32"
                                        height="200"
                                        x="312"
                                        y="216"
                                      ></rect>
                                      <path d="M328,88V40c0-13.458-9.488-24-21.6-24H205.6C193.488,16,184,26.542,184,40V88H64v32H448V88ZM216,48h80V88H216Z"></path>
                                    </svg>
                                    <span>Remove</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </li>
                        </div>
                      ))}
                    </ul>
                  </div>
                  <div className="w-full">
                    <div className="flex bg-white rounded-2xl flex-col space-y-4 md:w-full p-5 divide-gray-700">
                      <h2 className="text-xl font-semibold">Order items</h2>

                      <div className="pt-4 space-y-2">
                        <div>
                          <div className="flex justify-between">
                            <span>Subtotal</span>
                            <span>{format_sellingPrice(subTotal)}</span>
                          </div>
                          <div className="flex items-center text-xs">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              className="w-3 h-3 mt-1 fill-current "
                            >
                              <path d="M485.887,263.261,248,25.373A31.791,31.791,0,0,0,225.373,16H64A48.055,48.055,0,0,0,16,64V225.078A32.115,32.115,0,0,0,26.091,248.4L279.152,486.125a23.815,23.815,0,0,0,16.41,6.51q.447,0,.9-.017a23.828,23.828,0,0,0,16.79-7.734L486.581,296.479A23.941,23.941,0,0,0,485.887,263.261ZM295.171,457.269,48,225.078V64A16.019,16.019,0,0,1,64,48H225.373L457.834,280.462Z"></path>
                              <path d="M148,96a52,52,0,1,0,52,52A52.059,52.059,0,0,0,148,96Zm0,72a20,20,0,1,1,20-20A20.023,20.023,0,0,1,148,168Z"></path>
                            </svg>
                            <span className="">{itemCount} Items</span>
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <span>Discount</span>
                          <span>-{format_sellingPrice(discount)}</span>
                        </div>
                      </div>
                      <div className="pt-4 space-y-2">
                        <div className="flex flex-col">
                          <button
                            onClick={checkPromoCode}
                            className="w-full my-1 font-semibold text-right rounded"
                          >
                            Apply Promocode
                          </button>
                          <input
                            onChange={onEnterPromoCode}
                            type="text"
                            id="promo"
                            className="shadow-sm mr-1 bg-gray-50 cursor-pointer border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-fit p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                            placeholder="Enter your code"
                          />
                          <button
                            onClick={checkPromoCode}
                            className="w-full py-2 mt-3 font-semibold border rounded"
                          >
                            Apply
                          </button>
                        </div>
                        <div className="space-y-6">
                          <div className="flex justify-between">
                            <span>Taxes</span>
                            <span className="font-semibold">{TAX * 100}%</span>
                          </div>
                          <div className="flex justify-between">
                            <span>Total</span>
                            <span className="font-semibold">
                              {format_sellingPrice(total)}
                            </span>
                          </div>

                          <Button
                            isLoading={loading === true && true}
                            onClick={(e) => {
                              generateInvoice(e);
                            }}
                            sx={{
                              backgroundColor: "#f9961d",
                              color: "#000",
                              width: "100%",
                              fontWeight: "bold",
                              border: "#f9961d",
                            }}
                            className="flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                            variant="outlined"
                          >
                            Checkout
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="p-4 empty-product">
                  <img
                    className="w-96 m-auto"
                    src="https://res.cloudinary.com/webalys/image/private/w_800,h_800,ar_1/f_auto/v1/icons/barcelona/shopping/shopping/empty-cart-1-jtz6v64553iydq4a1tijb8.png"
                  />
                  <button
                    className="CartButton"
                    onClick={() => history.push("/patient/assessments")}
                  >
                    Add Items
                  </button>
                  <p className="text-gray-400 font-bold mt-3">
                    There are no assessments in the cart
                  </p>
                </div>
              )}
            </div>
          </CustomDrawer>
        </Flex>
        {basket && (
          <Text
            w="max-content"
            color={ethColor}
            fontSize="sm"
            fontWeight="700"
            me="6px"
          >
            {basket.length}
          </Text>
        )}
      </Flex>
      <SidebarResponsive routes={routes} />
      <Menu>
        <MenuButton p="0px">
          <Icon
            mt="6px"
            as={MdNotificationsNone}
            color={navbarIcon}
            w="18px"
            h="18px"
            me="10px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="20px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
          mt="22px"
          me={{ base: "30px", md: "unset" }}
          minW={{ base: "unset", md: "400px", xl: "450px" }}
          maxW={{ base: "360px", md: "unset" }}
        >
          <Flex jusitfy="space-between" w="100%" mb="20px">
            <Text fontSize="md" fontWeight="600" color={textColor}>
              Notifications
            </Text>
            <Text
              fontSize="sm"
              fontWeight="500"
              color={textColorBrand}
              ms="auto"
              cursor="pointer"
            >
              Mark all read
            </Text>
          </Flex>
          <Flex flexDirection="column">
            {notifications &&
              notifications.map((i) => (
                <MenuItem
                  key={i.id}
                  _hover={{ bg: "none" }}
                  _focus={{ bg: "none" }}
                  px="0"
                  borderRadius="8px"
                  mb="10px"
                >
                  <ItemContent data={i} />
                </MenuItem>
              ))}
          </Flex>
        </MenuList>
      </Menu>
      {
        (locked === process.env.NEXT_PUBLIC_LOCKED,
        locked && <ThemeEditor navbarIcon={navbarIcon} />)
      }
      <Menu>
        <MenuButton p="0px">
          <Avatar
            _hover={{ cursor: "pointer" }}
            color="white"
            name={user?.displayName}
            bg="#11047A"
            size="sm"
            w="40px"
            h="40px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              👋&nbsp; Hey, {user?.displayName}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm">Profile Settings</Text>
            </MenuItem>
            <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm">Newsletter Settings</Text>
            </MenuItem>
            <MenuItem
              onClick={() => {
                removeCookies("UserToken");
                history.push("/auth");
              }}
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              color="red.400"
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm">Log out</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}
function format_sellingPrice(value) {
  return Number(value).toLocaleString("INR", {
    style: "currency",
    currency: "INR",
  });
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
