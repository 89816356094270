// Chakra imports
import {
  AvatarGroup,
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import { useStateValue } from "contexts/StateProvider";
// Assets
import React, { useState } from "react";
import { IoHeart, IoHeartOutline } from "react-icons/io5";
import { toast } from "react-toastify";
export default function NFT(props) {
  const [{ basket }, dispatch] = useStateValue();
  const { image, name, item, stock, sellingPrice, index, costPrice } = props;
  const [like, setLike] = useState(false);
  const textColor = useColorModeValue("navy.700", "white");
  const textColorBid = useColorModeValue("brand.500", "white");
  const isAdded = basket.find((item) => item.name === name);
  const onRemoveProduct = (i) => {
    const filteredProduct = basket.filter((product, index) => {
      return product.name != i;
    });
    dispatch({
      type: "UPDATE_CART",
      basket: filteredProduct,
    });
  };

  const increase = (index, event, name) => {
    const valueInt = parseInt(event);
    const newEl = basket.find((i) => i.name === name);
    const newIndex = basket.findIndex((i) => i.name === name);

    if (newEl.stock <= 0) {
      toast.error("No Stock Available", { toastId: index });
    } else {
      newEl.quantity = newEl.quantity + 1;
      newEl.stock = newEl.stock - 1;
    }
    basket[newIndex] = newEl;

    dispatch({
      type: "UPDATE_CART",
      basket: basket,
    });
  };

  const decrease = (index, event, name) => {
    const valueInt = parseInt(event);
    const clonebasket = basket;

    if (clonebasket[index].quantity <= 1) {
      onRemoveProduct(index);
      toast.success("Product Removed", { toastId: index });
    } else {
      clonebasket[index].quantity = clonebasket[index].quantity - 1;
      clonebasket[index].stock = clonebasket[index].stock + 1;
      dispatch({
        type: "UPDATE_CART",
        basket: clonebasket,
      });
    }
  };

  return (
    <Card p="20px">
      <Flex direction={{ base: "column" }} justify="center">
        <Box mb={{ base: "20px", "2xl": "20px" }} position="relative">
          <Image
            src={image}
            w={{ base: "100%", "3xl": "100%" }}
            h={{ base: "100%", "3xl": "100%" }}
            borderRadius="20px"
          />
          <Button
            position="absolute"
            bg="white"
            _hover={{ bg: "whiteAlpha.900" }}
            _active={{ bg: "white" }}
            _focus={{ bg: "white" }}
            p="0px !important"
            top="14px"
            right="14px"
            borderRadius="50%"
            minW="36px"
            h="36px"
            onClick={() => {
              setLike(!like);
            }}
          >
            <Icon
              transition="0.2s linear"
              w="20px"
              h="20px"
              as={like ? IoHeart : IoHeartOutline}
              color="brand.500"
            />
          </Button>
        </Box>
        <Flex flexDirection="column" justify="space-between" h="100%">
          <Flex
            justify="space-between"
            direction={{
              base: "row",
              md: "column",
              lg: "row",
              xl: "column",
              "2xl": "row",
            }}
            mb="auto"
          >
            <Flex direction="column">
              <Text
                color={textColor}
                fontSize={{
                  base: "xl",
                  md: "lg",
                  lg: "lg",
                  xl: "lg",
                  "2xl": "md",
                  "3xl": "lg",
                }}
                casing="capitalize"
                mb="5px"
                fontWeight="bold"
                me="14px"
              >
                {name}
              </Text>
              <Text
                color="secondaryGray.600"
                fontSize={{
                  base: "sm",
                }}
                fontWeight="400"
                me="14px"
              >
                In Stock: {stock}
              </Text>
              <Text
                color="secondaryGray.600"
                fontSize={{
                  base: "sm",
                }}
                fontWeight="400"
                me="14px"
              >
                Cost Price: {costPrice}
              </Text>
            </Flex>
          </Flex>
          <Flex
            align="start"
            justify="space-between"
            direction={{
              base: "row",
              md: "column",
              lg: "row",
              xl: "column",
              "2xl": "row",
            }}
            mt="25px"
          >
            <Text fontWeight="700" fontSize="sm" color={textColorBid}>
              ₹{sellingPrice}
            </Text>

            {!isAdded ? (
              <Link
                onClick={(e) => {
                  dispatch({
                    type: "ADD_TO_CART",
                    item: { ...item, quantity: 1, stock: stock - 1 },
                  });
                }}
                mt={{
                  base: "0px",
                  md: "10px",
                  lg: "0px",
                  xl: "10px",
                  "2xl": "0px",
                }}
              >
                <Button
                  disabled={stock <= 0 ? true : false}
                  variant="darkBrand"
                  color="white"
                  fontSize="sm"
                  fontWeight="500"
                  borderRadius="70px"
                  px="24px"
                  py="5px"
                >
                  Add to Cart
                </Button>
              </Link>
            ) : (
              <Link
                onClick={(e) => {
                  onRemoveProduct(name);
                }}
                mt={{
                  base: "0px",
                  md: "10px",
                  lg: "0px",
                  xl: "10px",
                  "2xl": "0px",
                }}
              >
                <Button
                  disabled={stock <= 0 ? true : false}
                  variant="darkBrand"
                  color="white"
                  fontSize="sm"
                  fontWeight="500"
                  borderRadius="70px"
                  px="24px"
                  py="5px"
                >
                  Remove from Cart
                </Button>
              </Link>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}
