import React from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Icon,
} from "@chakra-ui/react";
import { CgShoppingCart } from "react-icons/cg";

function CustomDrawer({ children, size }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Icon
        className="bg-transparent"
        onClick={onOpen}
        mt="6px"
        as={CgShoppingCart}
        w="18px"
        h="18px"
        cursor={"pointer"}
        me="10px"
      />
      <Drawer
        isOpen={isOpen}
        placement="right"
        size={size}
        onClose={onClose}
        closeOnOverlayClick={true}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          {children}
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default CustomDrawer;
