/* eslint-disable */
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { Select } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { AndroidLogo, AppleLogo, WindowsLogo } from "components/icons/Icons";
import Menu from "components/menu/MainMenu";
import CustomModal from "components/Modal";
import db from "contexts/Firebase";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getFirestore,
  setDoc,
} from "firebase/firestore/lite";
import React, { useEffect, useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { getDocuments } from "utils/Helper";
import { inputValueChange } from "utils/Helper";

export default function DevelopmentTable(props) {
  const { columnsData } = props;
  const [state, setstate] = useState([]);
  const [tableData, setTableData] = useState([]);

  const get = async () => {
    const neww = await getDocuments(db, "products");
    setTableData(neww);
    return neww;
  };
  const deleteProduct = async (uid) => {
    await deleteDoc(doc(db, "products", uid));
    get();
  };
  useEffect(() => {
    get();
  }, []);
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 11;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("secondaryGray.500", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = JSON.parse(localStorage.getItem("UserToken"));
  const sch = [
    {
      name: "Product Name",
      stock: "Product Name",
      category: "Product Name",
      description: "Product Name",
      image: "Product Name",
    },
  ];
  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <CustomModal
        title="Add product"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      >
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await setDoc(doc(db, "products", state.name), state);
            await addDoc(collection(db, "notifications"), {
              label: "Updated Stock",
              notification: `${state.name} updated to ${state.stock} items at a price of ${state.sellingPrice} by ${user?.displayName}`,
              id: new Date().getTime(),
              createdAt: new Date().toLocaleDateString(),
              isRead: false,
              user: user,
            });
            get();
            onClose();
          }}
        >
          <FormControl my={2} isRequired>
            {/* Name */}
            <FormLabel>Product Name</FormLabel>
            <Input
              required
              onChange={(e) => inputValueChange(e, 1, "name", state, setstate)}
              value={state.name}
            />

            <FormLabel my={2}>Selling Price</FormLabel>
            <NumberInput defaultValue={state.sellingPrice}>
              <NumberInputField
                required
                onChange={(e) =>
                  inputValueChange(e, 1, "sellingPrice", state, setstate)
                }
                defaultValue={state.sellingPrice}
              />
            </NumberInput>
            <FormLabel my={2}>Cost Price</FormLabel>
            <NumberInput defaultValue={state.sellingPrice}>
              <NumberInputField
                required
                onChange={(e) =>
                  inputValueChange(e, 1, "costPrice", state, setstate)
                }
                defaultValue={state.costPrice}
              />
            </NumberInput>
            {/* stock */}
            <FormLabel my={2}>Stock</FormLabel>

            <NumberInput defaultValue={state.stock}>
              <NumberInputField
                required
                onChange={(e) =>
                  inputValueChange(e, 1, "stock", state, setstate)
                }
                value={state.stock}
              />
              {/* <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper> */}
            </NumberInput>
            {/* <FormLabel my={2}>Category</FormLabel>
            <Select
              required
              onChange={(e) =>
                inputValueChange(e, 1, "stock", state, setstate)
              }
              placeholder="Select option"
            >
              <option value="Shoes">Shoes</option>
            </Select> */}
            <Button mt={5} colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button type="submit" colorScheme="blue" mt={5} variant="outline">
              Submit
            </Button>
          </FormControl>
        </form>
      </CustomModal>
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          All Products
        </Text>
        <Button variant={"outline"} colorScheme="blue" onClick={onOpen}>
          Add product
        </Button>
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "NAME") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "STOCK") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "sellingPrice") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "ACTIONS") {
                    data = (
                      <Flex color="blue" justifyContent="space-between">
                        <EditIcon
                          onClick={() => {
                            console.log(row.original);
                            setstate(row.original);
                            onOpen();
                          }}
                          cursor={"pointer"}
                          w={6}
                          h={6}
                        />
                        <DeleteIcon
                          onClick={() => {
                            deleteProduct(row.values.name);
                          }}
                          cursor={"pointer"}
                          color="red"
                          w={6}
                          h={6}
                        />
                      </Flex>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor="transparent"
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
}
