// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore/lite";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA-QNTbcFRr6hjTpVMm76CJ6zYFS-FV3-w",
  authDomain: "billing-5545a.firebaseapp.com",
  projectId: "billing-5545a",
  storageBucket: "billing-5545a.appspot.com",
  messagingSenderId: "154735517241",
  appId: "1:154735517241:web:1a4d2154cbc9a22ea337e8",
  measurementId: "G-CC3QZR365D",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);

export default db;
